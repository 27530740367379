<template>
  <NuxtLink
    :to="getUrl"
    class="group flex rounded-[12px] text-new-black shadow-drop-0"
    :class="[
      {
        'flex-col gap-[16px] shadow-none':
          (!isTitleCard || isMobile) && !disablePreview,
      },
      {
        'mb-6 flex-col rounded-none border-b border-new-card--border pb-3 shadow-none':
          disablePreview,
      },
    ]"
  >
    <MainPageCardsReworkedCommonCardImageBlock
      v-if="!disablePreview"
      v-bind="{ data }"
      :aspect="aspect"
      class="relative w-full"
      @click.prevent="disablePlay ? router.push(getUrl) : emit('open:video')"
    >
      <!-- :class="
        isTitleCard
          ? 'w-full md:w-7/12 min-h-[250px] xl:min-h-[514px] max-h-[514px]'
          : 'md:min-h-[292px] max-h-[292px]'
      " -->
      <div
        v-if="!disablePlay"
        class="absolute left-[50%] top-[50%] z-40 translate-x-[-50%] translate-y-[-50%]"
      >
        <SvgIcon
          name="play-circle-2"
          class="h-[54px] w-[54px] text-white-100"
        />
      </div>
    </MainPageCardsReworkedCommonCardImageBlock>

    <div
      v-if="disablePreview"
      class="z-20 mb-[4px] w-fit rounded-r-[4px] py-[1px] pl-[12px] pr-[4px] text-[14px] font-bold uppercase leading-[17px] text-white-100"
      :class="getDataByModel?.labelBgColor"
    >
      <slot name="badge">
        {{ data?.card_label || getDataByModel?.label }}
      </slot>
    </div>

    <div
      class="flex flex-col"
      :class="{ 'w-full p-[36px] md:w-5/12': isTitleCard }"
    >
      <h2
        class="z-30 font-roboto font-medium"
        :class="[
          {
            'mt-[12px] text-[20px] leading-[24px] text-new-test-black':
              disablePreview,
          },
          {
            'text-[18px] 2xl:text-[28px] 2xl:leading-[40px]':
              isTitleCard && !disablePreview,
          },
          {
            'text-center text-[16px] 2xl:text-[22px] 2xl:leading-[26px]':
              !isTitleCard && !disablePreview,
          },
        ]"
      >
        {{ data.title }}
      </h2>

      <p
        v-if="isTitleCard || disablePreview"
        class="z-30"
        :class="[
          {
            'mt-[16px] text-[14px] 2xl:text-[20px] 2xl:leading-[30px]':
              !disablePreview,
          },
          {
            'mt-[12px] text-[14px] leading-[18px] text-new-card--title-black':
              disablePreview,
          },
        ]"
      >
        {{ shrinkText(truncTags(data?.announce), 150) }}
      </p>
    </div>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isTitleCard: { type: Boolean, default: false },
  disablePlay: { type: Boolean, default: false },
  disablePreview: { type: Boolean, default: false },
  aspect: {
    type: Object,
    default: () => ({
      w: 16,
      h: 9,
    }),
  },
});

const emit = defineEmits(['open:video']);

const router = useRouter();
const { isMobile } = useBreakpoint();
const getCardSettings = useCardSettings();
const { shrinkText, truncTags } = useUtils();

const getDataByModel = computed(() => getCardSettings(props.data));

const getUrl = computed(() => {
  return (
    props.data?.url ?? `${getDataByModel?.value?.hrefBase}${props.data?.slug}`
  );
});
/*
import CardImageBlock from '@/components/MainPage/CardsReworked/Common/CardImageBlock';
import getCardSettings from '@/mixins/getCardSettings';
import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';

export default {
  components: { CardImageBlock },

  mixins: [getCardSettings, getScreenBreakpoint],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isTitleCard: { type: Boolean, default: false },
    disablePlay: { type: Boolean, default: false },
    disablePreview: { type: Boolean, default: false },
    aspect: {
      type: Object,
      default: () => ({
        w: 16,
        h: 9,
      }),
    },
  },

  computed: {
    getDataByModel() {
      return this.getCardSettings(this.data);
    },

    getUrl() {
      return (
        this.data?.url ?? `${this.getDataByModel?.hrefBase}${this.data?.slug}`
      );
    },
  },
};
*/
</script>
